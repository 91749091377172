<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
        <div class="row">
            <div class="col-lg-7 px-0">
                <div class="card">
                    <div class="card-body">
                        <h4 class="header-title font-weight-bold"> List of brands</h4>
                        <div>
                            <lottie-loader :loading="showLoader" />
                        </div>
                        <p class="text-muted font-13 mb-4"></p>
                        <div class="row mb-md-2">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select
                                                v-model="perPage"
                                                size="sm"
                                                :options="pageOptions"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div
                                        id="tickets-table_filter"
                                        class="dataTables_filter text-md-right"
                                >
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0" id="table">
                            <b-table
                                    table-class="table table-centered w-100"
                                    thead-tr-class="bg-light"
                                    :items="ListOfBrands"
                                    :fields="tableFields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    @filtered="onFiltered"
                            >

                                <template #cell(phone)="data">
                                    <span class="font-14 text-dark">{{ data.item.phone }}</span>
                                </template>
                                <template #cell(dateCreated)="data">
                                    <span class="font-12 font-weight-bold text-dark">{{ data.item.dateCreated | moment("MMMM Do YYYY") }}</span>
                                </template>
                                <template #cell(name)="data">
                                    <span class="font-14 font-weight-bold text-dark">   {{ data.item.name}}</span>
                                </template>
                                <template #cell(actions)="data">
                                    <a class="mr-2 btn btn-danger" style="cursor: pointer"  href="#" @click="DeleteBrand(data.item)" > <i class="mdi mdi-trash-can"></i>Delete</a>
                                    <a class="mr-2 btn btn-info" style="cursor: pointer"  href="#" @click="EditBrand(data.item.id)"> <i class="mdi mdi-pencil-minus"></i>Edit</a>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div
                                        class="dataTables_paginate paging_simple_numbers float-right"
                                >
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="rows"
                                                :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 ">
                <div class="card">
                    <div class="card-body">
                        <h4 class="header-title font-weight-bold mb-3"> Create / Update Brand</h4>

                        <form action="#" @submit.prevent="CreateOrUpdateBrand">

                            <div class="form-group mb-3">
                                <label for="name">Brand name</label>
                                <input
                                        class="form-control"
                                        v-model="brandAdd.name"
                                        type="text"
                                        id="name"
                                        name="name"
                                        placeholder="Enter brand name"
                                        v-validate="'required'"
                                        :class="{'is-invalid': errors.has('name') }"
                                />
                                <span v-show="errors.has('name')" class="help text-danger">{{ errors.first('name') }}</span>
                            </div>

                            <div class="form-group mb-3">
                                <label for="name">Phone number</label>
                                <input
                                        class="form-control"
                                        v-model="brandAdd.phone"
                                        type="text"
                                        id="phone"
                                        name="phone"
                                        placeholder="Enter brand phone number"
                                        v-validate="'required'"
                                        :class="{'is-invalid': errors.has('phone') }"
                                />
                                <span v-show="errors.has('phone')" class="help text-danger">{{ errors.first('phone') }}</span>
                            </div>
                            <div class="form-group mb-3">
                                <label for="email">Email address</label>
                                <input
                                        class="form-control"
                                        v-model="brandAdd.email"
                                        type="text"
                                        id="email"
                                        name="email"
                                        placeholder="Enter brand email number"
                                        :class="{'is-invalid': errors.has('email') }"
                                />
                            </div>

                            <div class="form-group mb-3">
                                <label for="name">Brand link(Url)</label>
                                <input
                                        class="form-control"
                                        v-model="brandAdd.link"
                                        type="text"
                                        id="link"
                                        placeholder=""
                                />
                            </div>
                            <div class="form-group mb-3">
                                <label for="name">Brand Logo Url</label>
                                <input
                                        class="form-control"
                                        v-model="brandAdd.logoUrl"
                                        type="text"
                                        id="logoUrl"
                                        placeholder=""
                                />
                            </div>

                            <div class="form-group mb-3">
                                <label for="name">Brand Logo thumbnail url</label>
                                <input
                                        class="form-control"
                                        v-model="brandAdd.logoThumbnailUrl"
                                        type="text"
                                        id="logoThumbnailUrl"
                                        placeholder=""
                                />
                            </div>
                            <div class="form-group mb-0 text-center">
                                <button class="btn btn-primary float-right" :class="brandAdd.id ? 'btn-warning' : 'btn-primary'" type="submit">
                                    {{AddOrUpdateBrand}}
                                </button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
    import Layout from "../../../layouts/main";
    import PageHeader from "@/components/Page-header";
    import LottieLoader from "../../../../components/LottieLoader";
    import BaseUrl from "../../../../components/constants/index"
    import ToastConfigAlert from "../../../../../ToastConfig";
    import SweetAlertWrapper from "../../../../../sweetAlert";
    import SweetAlertConfig from "../../../../../SwalConfig"

    export default {
        name: "ManageBrands",
        components : {
            LottieLoader,
            Layout,
            PageHeader
        },
        data() {
            return {
                showLoader : false,
                title: "Manage Brands",
                AddOrUpdateBrand : 'Add brand',
                selectedData :{},
                items: [
                    {
                        text: "",
                    },
                    {
                        text: "Dashboards",
                    },
                    {
                        text: "Brands",
                        active: true,
                    },
                ],
                brandAdd : {
                    id:'',
                  name:'',
                  email:'',
                  phone:'',
                  link :'',
                  logoUrl:'',
                  logoThumbnailUrl :''
                },
                ListOfBrands : [],
                ListOfBrandstRaw :[],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortDesc: true,
                sortBy: "id",

                tableFields: [
                    {
                        thClass: 'd-none', tdClass: 'd-none',
                        key: "id",
                        sortable: true,
                    },
                    {
                        label:"Name",
                        key: "name",
                        sortable: true,
                    },
                    {
                        label :"Phone number",
                        key: "phone",
                        sortable: true,
                    },
                    {
                        label:"Date createed",
                        key: "dateCreated",
                        sortable: true,
                    },
                    {
                        key: "actions",
                        sortable: true,
                    },
                ],
            }
        },
        methods : {
            EditBrand(Id) {
                 const selectedData = this.ListOfBrandstRaw.find((data) => data.id === Id)
                this.clearAddBrand();
                this.AddOrUpdateBrand = "Update Brand"
                this.brandAdd.id = selectedData.id;
                this.brandAdd.phone = selectedData.phoneNumber;
                this.brandAdd.email = selectedData.emailAddress;
                this.brandAdd.name = selectedData.name;
                this.brandAdd.logoUrl = selectedData.logo;
                this.brandAdd.link = selectedData.link;
                this.brandAdd.logoThumbnailUrl = selectedData.logoThumbnail;
            },
            DeleteBrand(data) {

                this.sweetAlert.showDialogYesOrNo(Object.assign(SweetAlertConfig, {
                    title : `Delete ${data.name} ?`,
                    confirmButtonText :'Yes Delete'
                }), () => {


                    this.showLoader = true

                    BaseUrl.AxiosBearer.delete("/store/brands/"+data.id).then((response) => {
                        this.showLoader = false
                        if(!response.data.status) {
                            this.$bvToast.toast(response.data.message, ToastConfigAlert)
                        }
                        else {
                            this.GetListOdBrands()
                            this.sweetAlert.showMessage("Brand deleted!  ",response.data.message)
                        }
                    }).catch((error) => {
                        this.showLoader = false
                        this.$bvToast.toast(error.message, ToastConfigAlert)
                    })
                })

            },
            CreateOrUpdateBrand() {
                this.$validator.validateAll().then((result) => {
                    if (result) {

                        this.sweetAlert.showDialogYesOrNo(Object.assign(SweetAlertConfig, {
                            title : `${this.brandAdd.id ? 'Update Brand ?' : 'Add Brand ?'}`,
                            confirmButtonText :`Yes ${this.brandAdd.id  ? 'Update' : 'Add'}`
                        }), () => {

                            this.showLoader = true
                            if(this.brandAdd.id === '' || this.brandAdd.id === null) {
                                this.AddBrand()
                            }
                            else {
                                this.UpdateBrand()
                            }
                            this.AddOrUpdateBrand = "Add Brand"

                        })

                    }
                })
            },
            AddBrand() {
                const userData = {
                    name : this.brandAdd.name,
                    phoneNumber : this.brandAdd.phone,
                    emailAddress : this.brandAdd.email,
                    link : this.brandAdd.link,
                    logo : this.brandAdd.logoUrl,
                    logoThumbnail : this.brandAdd.logoThumbnailUrl
                }

                BaseUrl.AxiosBearer.post("/store/brands", userData).then((response) => {
                    this.showLoader = false
                    if(!response.data.status) {
                        this.$bvToast.toast(response.data.message, ToastConfigAlert)
                    }
                    else {
                        this.GetListOdBrands()
                        this.clearAddBrand()
                        this.sweetAlert.showMessage("Brand created!  ",response.data.message)
                    }
                }).catch((error) => {
                    this.showLoader = false
                    this.$bvToast.toast(error?.message, ToastConfigAlert)
                })
            },
            UpdateBrand() {
                const userData = {
                    id : this.brandAdd.id,
                    name : this.brandAdd.name,
                    emailAddress : this.brandAdd.email,
                    phoneNumber : this.brandAdd.phone,
                    link : this.brandAdd.link,
                    logo : this.brandAdd.logoUrl,
                    logoThumbnail : this.brandAdd.logoThumbnailUrl
                }
                BaseUrl.AxiosBearer.put("/store/brands", userData).then((response) => {
                    this.showLoader = false
                    if(!response.data.status) {
                        this.$bvToast.toast(response.data.message, ToastConfigAlert)
                    }
                    else {
                        this.GetListOdBrands()
                        this.clearAddBrand()
                        this.sweetAlert.showMessage("Supplier updated!  ",response.data.message)
                    }
                }).catch((error) => {
                    this.showLoader = false
                    this.$bvToast.toast(error?.message, ToastConfigAlert)
                })
            },
            clearAddBrand() {
                this.brandAdd = {
                    link: '',
                    name: '',
                    logoThumbnailUrl: '',
                    logoUrl: '',
                    phone: '',
                    email: ''
                }
                this.$validator.reset();
            },
            GetListOdBrands() {
                this.$http.get(`${BaseUrl.URL}/store/brands`).then((response) => {

                    if(response.data.status) {
                        this.ListOfBrands = []
                        this.ListOfBrandstRaw = []
                        this.ListOfBrandstRaw = response.data.payload

                        response.data.payload.forEach((data) => {
                            this.ListOfBrands.push({
                                id : data.id,
                                name : data.name,
                                phone : data.phoneNumber,
                                dateCreated : data.createdAt

                            })
                        })
                    }
                    else{
                        this.$bvToast.toast(response.data.message,ToastConfigAlert)
                    }

                }).catch((error) => {
                    console.log(" error : ", error)
                    this.$bvToast.toast(error.message,ToastConfigAlert)
                })
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering

                //  this.fundtransferHistoryFiltered = filteredItems
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
        },
        created() {
            this.sweetAlert = new SweetAlertWrapper()
            this.GetListOdBrands()
        },
        computed: {
            rows() {
                return this.ListOfBrands.length;
            },
        },
    }
</script>

<style scoped>

</style>